"use client"; // Error components must be Client Components

import SomethingWentWrong from "@/components/Pages/SomethingWentWrong";
import { useEffect } from "react";
import { reportError } from "@/utility/error";

export default function Error({ error, reset }) {
  useEffect(() => {
    console.log(error);
    reportError(error, "/app/global-error");
  }, [error]);

  return (
    <html lang="en">
      <head>
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <link rel="icon" href="/images/favicon.svg" />
      </head>
      <body>
        <SomethingWentWrong error={error} reset={reset} />
      </body>{" "}
    </html>
  );
}
